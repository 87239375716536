/**
* Generated theme by bootstrap
* bootstrap
*/
/*@import 'bootstrap/dist/css/bootstrap.min.css';*/
/* @import '~angular-tree-component/dist/angular-tree-component.css'; */
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
/* @import 'angular-tree-component.css'; */

body {
  font-size: .875rem;
  padding-top: 4.75rem;
  margin: 0;
  background-color: #F3F6FD !important;
  z-index: 1000 !important;
}

.ng-dropdown-panel {
  position: relative !important;
    list-style: none;
    border: none;
    border-radius: 5px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: auto;
    white-space: nowrap;
    z-index: 101;
}

.ngx-spinner-overlay {
  z-index: 99999 !important;
}

// Botoes


.custom-btn {
  font-family: Poppins;
  font-size: 18px;
  line-height: 1.5;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  outline: none !important;
  border: none;
}

.custom-btn-azul {
  color: #fff;
  background: linear-gradient(154deg, #0f71d5, #0f71d5); //linear-gradient(154deg, #0d61b6, #0f71d5);
  background-color: #0d61b6;
}

.custom-btn-azul:hover {
  box-shadow: #00418117 0 1px 20px, #00418117 0 1px;
  background: #004181;
}

.custom-btn-vermelho {
  color: #fff;
  background: linear-gradient(154deg, #BB2D3B, #ac2936); //linear-gradient(154deg, #0d61b6, #0f71d5);
  background-color: #BB2D3B;
}

.custom-btn-vermelho:hover {
  box-shadow: #00418117 0 1px 20px, #00418117 0 1px;
  background: rgb(128, 29, 39);
}

.custom-btn-cinza {
  color: #fff;
  background: linear-gradient(154deg, #99a6b1, #99a6b1); //linear-gradient(154deg, #808b94, #99a6b1);
  background-color: #6C757D;
}

.custom-btn-cinza:hover {
  box-shadow: #00418117 0 1px 20px, #00418117 0 1px;
  background: #6C757D;
}

.custom-btn-light {
  width: 35px !important;  
  height: 35px !important; 
  border-radius: 50% !important; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #c7c7c7;
  background: #a0a0a0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 0px 1px 0px;
}

.custom-btn-light:hover {
  box-shadow: #00418117 0 1px 20px, #00418117 0 1px;
  background: #777777;
}




/*
 * lateral Sidebar
 */
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: none;
}

#wrapper.toggled {
  padding-left: 250px;
  display: block;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  margin-left: -250px;
  overflow-y: auto;
  min-width: 250px;
  min-height: 94vh;
  background-color: #f8f9fa;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  /*   width: 250px; */
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 5px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #000;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  background: #dee2e6;
  /* background: rgba(255,255,255,0.55); */
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #fff;
  text-indent: 40px;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
  background-color: #0b522d !important
}


@media(min-width:768px) {
  #wrapper {
    padding-left: 250px;
    display: block;
  }

  #wrapper.toggled {
    padding-left: 0px;
    display: none;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}


.nav-link:hover,
.nav-link :focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}


/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.projects-section {
  flex: 2;
  background-color: #fff;
  border-radius: 32px;
  padding: 25px 32px 32px 32px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}


.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--main-color);
}

.btn-sistemas {
  font-size: 16px;
  color: #11101D;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  border: none;
  flex-shrink: 0;
  padding: 3px 9px;
  font-weight: 700;

}

.btn-sistemas:hover {
  background-color: #fff;
  box-shadow: rgba(65, 73, 75, 0.13) 0px 0px 10px;
}

.view-actions {
  display: flex;
  align-items: center;
  justify-content: end;
}

.view-btn {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: var(--main-color);
  margin-left: 8px;
  transition: 0.2s;
  background-color: #1f1c2e0e;
}

.view-btn:hover {
  background-color: #1f1c2eef;
  color: #fff;
}

.feather-search {
  border: none;
  background: none;
}

@media screen and (max-width: 980px) {
  .project-boxes.jsGridView .project-box-wrapper {
    width: 50%;
  }
}

.input-wrapper {
  border-radius: 20px;
  background-color: var(--search-area-bg);
  padding-right: 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  //max-width: 50%;
  color: var(--light-font);
  box-shadow: 0px 0px 3px 1px rgba(136, 148, 171, 0.3), 0 24px 20px -24px rgba(71, 82, 107, 0.2);
  overflow: hidden;
}

.custom-input {
  border: none;
  flex: 1;
  outline: none;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
  background-color: var(--search-area-bg);
  color: var(--main-color);
}

.custom-input:placeholder {
  color: var(--main-color);
  opacity: 0.6;
}


@media screen and (max-width: 520px) {
  .project-boxes {
    overflow-y: visible;
  }

  .project-boxes.jsGridView .project-box-wrapper {
    width: 100%;
  }

  .project-boxes.jsListView .project-box-header>span {
    font-size: 10px;
  }

  .project-boxes.jsListView .project-box>* {
    margin-right: 10px;
  }

  .project-boxes.jsListView .more-wrapper {
    right: 2px;
    top: 10px;
  }

  .view-btn {
    width: 24px;
    height: 24px;
  }

  .profile-btn img {
    width: 24px;
    height: 24px;
  }

  .custom-input {
    font-size: 14px;
  }

  .custom-input {
    max-width: 120px;
  }
}

//*******************
//   CSS Paginas    *
//                  *
//*******************



.section-header p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: 0.9;
  margin: 0;
  color: var(--main-color);
}

.project-boxes {
  margin: 0 -8px;
  overflow-y: auto;
}

.project-boxes.jsGridView {
  display: flex;
  flex-wrap: wrap;
}

.project-boxes.jsGridView .project-box-wrapper {
  width: 33.3%;
}

.project-boxes.jsListView .project-box {
  display: flex;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  //justify-content: space-around;
}

// .project-boxes.jsListView .project-box>* {
//   margin-right: 56px;
// }

.project-boxes.jsListView .more-wrapper {
  position: absolute;
  right: 16px;
  top: 16px;
}

.project-boxes.jsListView .project-box-content-header {
  //max-width: 120px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.project-boxes.jsListView .project-box-footer {
  text-align: center;
  justify-content: center;
}

.project-boxes.jsListView .box-dominio-wrapper {
  color: #4A4A4A;
  text-align: center;
  justify-content: center;

}

.project-boxes.jsListView .project-box-footer:after {
  display: none;
}

.project-boxes.jsListView .participants {
  margin-bottom: 8px;
}

.project-boxes.jsListView .project-box-content-header p {
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-boxes.jsListView .project-box-header>span {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-size: 12px;
}

.project-box-wrapper {
  padding: 8px;
  transition: 0.2s;
}

.box-content-header {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  opacity: 0.7;
}

.project-box {
  --main-color-card: #dbf6fd;
  border-radius: 30px;
  padding: 16px calc(5%);
  background-color: var(--main-color-card);
}

.project-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--main-color);
}

.project-box-header span {
  color: #4A4A4A;
  opacity: 0.7;
  font-size: 14px;
  line-height: 16px;
}



.project-box-content-header {
  text-align: center;
}

.project-box-content-header p {
  margin: 0;
}

.project-box-wrapper {
  padding: 8px;
  transition: 0.2s;
}


.participants img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
}

.participants img:not(:first-child) {
  margin-left: -8px;
}

.box-progress {
  display: block;
  height: 4px;
  border-radius: 6px;
}

.box-progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  margin: 8px 0;
}

.box-progress-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 0;
}

.box-progress-percentage {
  text-align: right;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.modal-content {
  border-radius: 12px !important;
  border: none !important;
}

// PAGINATION

ngb-pagination::ng-deep ul.pagination>li.page-item:first-child>a {
  border-top-left-radius: 30%;
  border-bottom-left-radius: 30%;
}

ngb-pagination::ng-deep ul.pagination>li.page-item:last-child>a {
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
}

ngb-pagination::ng-deep ul.pagination>li.page-item:first-child>a,
ngb-pagination::ng-deep ul.pagination>li.page-item:last-child>a {
  color: white;
  //background-color: #c1b400;
  border-block-width: 0px;
  height: 38px;
}

div.div-pagination {
  padding-inline: 0 !important;

  label#paginationInputLabel {
    color: white;
    background-color: #007CC1;
    margin-inline: 0 !important;
    padding-inline: 10px;
  }
}

.pagination > .active > a , .pagination > .active > a:hover, .pagination > .active > a:focus {
  background-color: #216ab4 !important;
  border-color: #216ab4 !important;
}

// PAGINATION FIM

